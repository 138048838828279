import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="hero">
        <div className="hero-content-wrapper">
            <div className="hero-text-wrapper">
                <div className="hero-text">
                    <h1>GoJohnnie<sup>&trade;</sup></h1>
                    <h2>Coming Soon</h2>
                    <p>We're beavering away on our new website.</p>
                    <p>Please check back regularly for updates.</p>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
